import { Filter, type FilterData } from 'components/feature/filter';
import { Card } from 'components/markdown/card';
import { CatalogueCard } from 'components/markdown/catalogue-card.tsx';
import { type Spec, useContainerData } from 'hooks';
import { useState } from 'react';

export interface CardContainerProps {
  specs: Array<Spec>;
  labels: {
    title: string;
    apiReferenceLabel: string;
    apiOverviewLabel: string;
  };
  onChangeFilter?: (value: Array<FilterData>) => void;
  initialFilterData?: Array<FilterData>;
  useCatalogueCard?: boolean;
}

export const CardContainerGrid = ({ specs, labels, useCatalogueCard }: CardContainerProps) => (
  <div
    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5 auto-rows-fr"
    data-testid="card-container"
  >
    {specs?.map((spec) => {
      return useCatalogueCard ? (
        <CatalogueCard
          key={spec.link + spec.description}
          heading={spec.heading}
          description={spec.description}
          tags={[spec.version].concat(spec.deprecated ? ['deprecated'] : [])}
          ctaPrimary={spec.hasDocs ? { label: labels.apiOverviewLabel, href: spec.docsLink } : undefined}
          ctaSecondary={{ label: labels.apiReferenceLabel, href: spec.link }}
        />
      ) : (
        <Card
          key={spec.link + spec.description}
          heading={spec.heading}
          description={spec.description}
          ctaPrimary={spec.hasDocs ? { label: labels.apiOverviewLabel, href: spec.docsLink } : undefined}
        />
      );
    })}
  </div>
);

const filterSpecsCallback = (spec: Spec, currentFilter: Array<FilterData>) => {
  const { team, tags } = spec.x_pub_settings;

  // Should hide spec if tags are not defined and filter is selected.
  if (!tags) return false;

  // Filter on all types
  let allFound = true;
  // currentFilter.forEach(({ items }) => {
  for (const { items } of currentFilter) {
    if (!items.some((item) => tags.includes(item.value)) && !items.some((item) => team === item.value)) {
      allFound = false;
    }
  }

  return allFound;
};

const sortCardsCallback = (a: Spec, b: Spec) => (a.heading > b.heading ? 1 : -1);

export const CardContainer = ({
  specs,
  labels,
  initialFilterData,
  onChangeFilter,
  useCatalogueCard,
}: CardContainerProps) => {
  const [currentFilter, setCurrentFilter] = useState<Array<FilterData>>(initialFilterData || []);
  const filters = useContainerData({ specs });

  if (!filters || filters.length === 0) return null;
  const selectedFilter: Array<Spec> =
    currentFilter.length === 0 ? specs : specs.filter((spec) => filterSpecsCallback(spec, currentFilter));
  const sortedCards = selectedFilter.sort(sortCardsCallback);

  const onChangeFilterData = (value: Array<FilterData>) => {
    setCurrentFilter(value);
    if (onChangeFilter) {
      onChangeFilter(value);
    }
  };

  return (
    <>
      <Filter filters={filters} onChangeFilter={onChangeFilterData} labels={labels} currentFilter={currentFilter} />
      <CardContainerGrid specs={sortedCards} labels={labels} useCatalogueCard={useCatalogueCard} />
    </>
  );
};
