import { DynamicSVGIcon, type DynamicSVGIconProps } from 'components/images/dynamic-svg-icon';
import { Text } from 'components/typography/text';
import { Badge } from 'components/ui/badge';
import { DownloadFileLink, Link, LinkIcon } from 'components/ui/link';
import type { HTMLAttributes, ReactNode } from 'react';
import { cn } from 'utils/cn';

type CtaProps = {
  label: string;
  href: string;
  // adding this parameter will use DownloadFileLink component instead of Link
  // This name is used to save file to local
  downloadFilename?: string;
  heading?: string;
};

export type CatalogueCardProps = HTMLAttributes<HTMLDivElement> & {
  icon?: DynamicSVGIconProps;
  heading: string;
  ctaPrimary?: CtaProps;
  meta?: string;
  tags?: string[];
  ctaSecondary?: CtaProps;
  description?: string;
  children?: ReactNode;
};

const getCtaLink = (ctaProps?: CtaProps) => {
  if (ctaProps) {
    if (ctaProps.downloadFilename) {
      return (
        <DownloadFileLink
          icon={LinkIcon.Trailing}
          href={ctaProps.href}
          className="text-sm font-medium leading-normal"
          downloadFilename={ctaProps.downloadFilename}
        >
          {ctaProps.label}
        </DownloadFileLink>
      );
    }

    const ariaLabel =
      ctaProps.label.match(/api reference/i) || ctaProps.label.match(/overview/i)
        ? `${ctaProps.heading?.replace(/\s*api$/i, '')} ${ctaProps.label}`
        : ctaProps.label;
    return (
      <Link
        icon={LinkIcon.Trailing}
        href={ctaProps.href}
        className="text-sm font-medium leading-normal"
        ariaLabel={ariaLabel}
      >
        <span aria-hidden>{ctaProps.label}</span>
      </Link>
    );
  }

  return null;
};

export const CatalogueCard = ({
  meta,
  heading,
  ctaPrimary,
  ctaSecondary,
  className,
  icon,
  tags,
  description,
  children,
  ...props
}: CatalogueCardProps) => (
  <div
    className={cn('p-6 rounded-lg min-h-[14rem] grid h-full bg-neutral-100 dark:bg-dark-800', className)}
    data-testid="card"
    {...props}
  >
    <div className="flex flex-col justify-between h-full gap-4">
      <div className="flex flex-col gap-2">
        {icon && DynamicSVGIcon(icon)}
        {meta && (
          <div className="text-neutral-600 dark:text-dark-200 text-xs leading-normal mb-1" data-testid="card.meta">
            {meta}
          </div>
        )}
        <h4 data-testid="card.title">{heading}</h4>
        {(description || children) && (
          <Text
            data-testid="card.description"
            className="text-neutral-600 dark:text-dark-200 text-sm leading-normal line-clamp-4 text-ellipsis"
          >
            {description}
            {children}
          </Text>
        )}
        <div className="flex gap-2">
          {tags?.map((tag) => (
            <Badge key={tag} variant={tag === 'deprecated' ? 'deprecated' : 'default'}>
              {tag}
            </Badge>
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-2" data-testid="card.links">
        {getCtaLink(ctaPrimary ? { ...ctaPrimary, heading } : undefined)}
        {getCtaLink(ctaSecondary ? { ...ctaSecondary, heading } : undefined)}
      </div>
    </div>
  </div>
);
