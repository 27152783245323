import type { FilterData } from 'components/feature/filter';
import { Hero } from 'components/markdown/hero';
import type { PageProps } from 'gatsby';
import { useCatalogueData } from 'hooks';
import { useIntl } from 'react-intl';
import { CardContainer } from 'src/layouts/card-container';
import { PageLayout } from 'src/layouts/page-layout';

const StorageFiltersItemName = 'catalogue-filters';
const getInitialFilterState = () => {
  const storedFilter = typeof window !== 'undefined' ? sessionStorage.getItem(StorageFiltersItemName) : null;
  if (storedFilter) {
    return JSON.parse(storedFilter);
  }

  return [];
};

export const Catalogue = () => {
  const { formatMessage } = useIntl();
  const specs = useCatalogueData();
  const onChangeFilterHandler = (value: Array<FilterData>) => {
    sessionStorage.setItem(StorageFiltersItemName, JSON.stringify(value));
  };

  return (
    <div data-testid="catalogue-page">
      <Hero headline={formatMessage({ id: 'home.title' })} copy={formatMessage({ id: 'home.tagline' })} />
      <CardContainer
        useCatalogueCard
        specs={specs}
        labels={{
          title: formatMessage({ id: 'home.filter.title' }),
          apiReferenceLabel: formatMessage({
            id: 'home.cards.apiReferenceLabel',
          }),
          apiOverviewLabel: formatMessage({
            id: 'home.cards.apiOverviewLabel',
          }),
        }}
        onChangeFilter={onChangeFilterHandler}
        initialFilterData={getInitialFilterState()}
      />
    </div>
  );
};

export const CataloguePage = ({ location }: PageProps) => (
  <PageLayout location={location} isLargeContent title="Browse APIs">
    <Catalogue />
  </PageLayout>
);

export default CataloguePage;
